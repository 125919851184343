/* eslint-disable jsx-a11y/alt-text */
import React, {Component} from "react";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import "./App.css";
import axios from "axios";
class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      gender: "",
      age: "",
      telrep: "",
      zonerep: ""
    };
  }

  componentWillMount() {
    const {steps} = this.props;
    const {name, gender, age, telrep, zonerep} = steps;

    localStorage.setItem(
      "botuser",
      JSON.stringify({
        name: name.value,
        gender: gender.value,
        age: age.value,
        tel: telrep.value,
        address: zonerep.value
      })
    );
    this.setState({name, gender, age, telrep, zonerep});
  }

  render() {
    const {name, gender, age, telrep, zonerep} = this.state;
    return (
      <div style={{width: "100%"}}>
        <h6>Pou rezime</h6>
        <table>
          <tbody>
            <tr>
              <td>Non'w</td>
              <td>: {name.value}</td>
            </tr>
            <tr>
              <td>Seks</td>
              <td>: {gender.value}</td>
            </tr>
            <tr>
              <td>Laj</td>
              <td>:{age.value} ane</td>
            </tr>
            <tr>
              <td>Tel</td>
              <td>: {telrep.value}</td>
            </tr>
            <tr>
              <td>Zon</td>
              <td>: {zonerep.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object
};

Review.defaultProps = {
  steps: undefined
};
const regist_ht = [];
class SimpleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: {},
      infos: localStorage.getItem("botuser")
        ? JSON.parse(localStorage.getItem("botuser"))
        : null
    };
  }
  componentWillMount() {
    let self = this;

    axios
      .get("https://corona.lmao.ninja/countries/haiti")
      .then(function(response) {
        // handle success
        //
        localStorage.setItem("nb", JSON.stringify(response.data));
        self.setState({country: response.data}, () => {
          //alert(JSON.stringify(self.state.country.cases));
        });
        //console.log(response);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  }

  algo = () => {
    let res = -1;
    let test = localStorage.getItem("analyse")
      ? JSON.parse(localStorage.getItem("analyse"))
      : {};
    //alert(JSON.stringify(test.senptom.length));
    if (test && test.senptom) {
      if (test.senptom.length === 0) {
        res = 0;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour <= 3 &&
        test.etasante < 2
        // &&
        // test.testsouf === "Non" &&
        // test.zonexpoz === "Non"
      ) {
        res = 1;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour <= 3 &&
        test.etasante < 2
        // &&
        // test.testsouf === "Non" &&
        // test.zonexpoz === "Wi"
      ) {
        res = 2;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour <= 3 &&
        test.etasante < 2
        // &&
        // test.testsouf === "Wi" &&
        // (test.zonexpoz === "Wi" || test.zonexpoz === "Non")
      ) {
        res = 2;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour <= 3 &&
        test.etasante >= 2
        // &&
        // (test.testsouf === "Wi" || test.testsouf === "Non") &&
        // (test.zonexpoz === "Wi" || test.zonexpoz === "Non")
      ) {
        res = 2;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour > 3 &&
        test.etasante >= 2
        // &&
        // (test.testsouf === "Wi" || test.testsouf === "Non") &&
        // (test.zonexpoz === "Wi" || test.zonexpoz === "Non")
      ) {
        res = 2;
      } else if (
        (test.senptom.includes("Fyèv") ||
          test.senptom.includes("Tous") ||
          test.senptom.includes("Etenye") ||
          test.senptom.includes("Respirasyon")) &&
        test.nbjour > 3 &&
        test.etasante < 2
        // &&
        // (test.testsouf === "Wi" || test.testsouf === "Non") &&
        // (test.zonexpoz === "Wi" || test.zonexpoz === "Non")
      ) {
        res = 2;
      }
    }
    return res;
  };
  render() {
    let name = "koronabot";
    let senptom = "";
    let nbjour = 0;
    let etasante = 0;
    let testsouf = "";
    let zonexpoz = "";

    const {country} = this.state;
    return (
      <div
        className="App"
        style={{
          backgroundColor: "#011f41",
          minHeight: "100vh",
          fontFamily: "Quicksand,sans-serif",
          color: "#4a4a4a"
        }}>
        <div class="container">
          <div class="row">
            <div class="col-md-6" style={{margin: "auto"}}>
              <img
                style={{
                  height: 100,
                  width: 300,
                  objectFit: "contain",
                  marginTop: 20,
                  marginBottom: 20
                }}
                src="https://kdvr.com/wp-content/uploads/sites/11/2020/03/GettyImages-1204696189.jpg?w=2560&h=1440&crop=1"
              />
              <center>
                <div
                  style={{
                    fontSize: 10,
                    width: 300,
                    fontSize: 25,
                    color: "#fff",
                    border: "#fff solid 2px"
                  }}>
                  <tr>
                    <th style={{width: 100}}>Enfekte</th>
                    <th style={{width: 100}}>Geri</th>
                    <th style={{width: 100}}>Mouri</th>
                  </tr>
                  <tr>
                    <td
                      style={{width: 100, color: "orange", fontWeight: "bold"}}>
                      {country.cases}
                    </td>
                    <td
                      style={{width: 100, color: "green", fontWeight: "bold"}}>
                      {country.recovered}
                    </td>
                    <td style={{width: 100, color: "red", fontWeight: "bold"}}>
                      {country.deaths}
                    </td>
                  </tr>
                </div>
              </center>
              <h1
                style={{
                  marginTop: 20,
                  fontWeight: "600",
                  color: "#fff",
                  fontSize: "2.6rem",
                  textAlign: "center"
                }}>
                {"Prevansyon Kont Korona viris".toUpperCase()}
              </h1>

              <p
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontSize: " 1.4rem"
                }}>
                coronabothaiti se yon ajan programe ki la pou ede'w enfome sou
                KORONAVIRIS e pèmet ou fè swivi lè'w santi senptom ki lye a
                epidemi sa.
              </p>

              <a href={"https://syntaxstudio.io/"}>
                <p style={{color: "#fff"}}>Sipote pa:</p>
                <img
                  src={
                    "https://syntaxstudio.io/static/images/syntax-studio-white.png"
                  }
                  style={{width: 300, objectFit: "contain", marginTop: -20}}
                />
              </a>
            </div>

            <div class="col-md-6" style={{}}></div>
            <ChatBot
              floating={true}
              opened={true}
              headerTitle={name}
              steps={[
                {
                  id: "end-message2",
                  component: (
                    <h4>
                      {"Koman nou ka ede'w? touche yon'n  nan opsyon sa yo."}
                    </h4>
                  ),
                  trigger: "question",
                  hideInput: true
                },
                {
                  id: "question",
                  options: [
                    // {
                    //   value: 10,
                    //   label: localStorage.getItem("botuser")
                    //     ? "Afiche dosye evalyasyon'm yo."
                    //     : "Anrejistre pou w ka gen aksè pou w evalye tèt ou.",
                    //   trigger: localStorage.getItem("botuser")
                    //     ? "afiche"
                    //     : "anrejistre"
                    // },
                    {
                      value: 10,
                      label: "Afiche dosye evalyasyon'm yo.",
                      trigger: "afiche"
                    },
                    // {
                    //   value: 11,
                    //   label: "Konekte si w te deja anrejistre",
                    //   trigger: "konekte"
                    // },
                    {
                      value: 1,
                      label:
                        "1- Fè tès senptom ke ou santi yo chak jou. (Atansyon: se sèl MSPP ki ka konkli si w gen korona.)",
                      trigger: "swivi-senptom"
                    },

                    {
                      value: 2,
                      label: "2- Nimero sèvis ijans ",
                      trigger: "nimoro-ijans"
                    },
                    {
                      value: 3,
                      label: "3- Tès ofisyèl korona viris",
                      trigger: "tes-korona"
                    },
                    {
                      value: 4,
                      label: "4- Prevansyon kont korona viris, selon OMS",
                      trigger: "prevention-korona"
                    },

                    {
                      value: 5,
                      label: "5- Reyalite sou korona viris lan, selon OMS",
                      trigger: "desinfo-korona"
                    },
                    {
                      value: 6,
                      label: "6- Konbyen ka yo jwenn Haiti?",
                      trigger: "nb-korona"
                    }
                  ]
                },
                {
                  id: "konekte",
                  message: "Antre nimero ou te anrejistre a.",
                  trigger: "konekte-telrep"
                },
                {
                  id: "afiche",
                  component: (
                    <div className="">
                      <p>Done sa yo anrejistre sèlman sou aparè ou an.</p>
                      <div
                        className=" "
                        style={{fontSize: 10, textAlign: "left"}}>
                        {/* <tr>
                          <th className="col-md-1">Non'm</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("botuser")
                              ? JSON.parse(localStorage.getItem("botuser")).name
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Laj</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("botuser")
                              ? JSON.parse(localStorage.getItem("botuser"))
                                  .age + " ane"
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Tel</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("botuser")
                              ? JSON.parse(localStorage.getItem("botuser")).tel
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Zon</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("botuser")
                              ? JSON.parse(localStorage.getItem("botuser"))
                                  .address
                              : ""}
                          </th>
                        </tr>
                        */}

                        <tr>
                          <th className="col-md-1">Senptom</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("analyse")
                              ? JSON.parse(localStorage.getItem("analyse"))
                                  .senptom
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Depi</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("analyse")
                              ? JSON.parse(localStorage.getItem("analyse"))
                                  .nbjour + " jou"
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Eta sante</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("analyse")
                              ? JSON.parse(localStorage.getItem("analyse"))
                                  .etasante === 0
                                ? "byen"
                                : JSON.parse(localStorage.getItem("analyse"))
                                    .etasante === 1
                                ? "Pa mal"
                                : JSON.parse(localStorage.getItem("analyse"))
                                    .etasante === 2
                                ? "Mal"
                                : JSON.parse(localStorage.getItem("analyse"))
                                    .etasante === 3
                                ? "Tre mal"
                                : ""
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Tes souf reyisi</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("analyse")
                              ? JSON.parse(localStorage.getItem("analyse"))
                                  .testsouf
                              : ""}
                          </th>
                        </tr>
                        <tr>
                          <th className="col-md-1">Mewn te Espoze</th>
                          <th className="col-md-1">
                            :
                            {localStorage.getItem("analyse")
                              ? JSON.parse(localStorage.getItem("analyse"))
                                  .zonexpoz
                              : ""}
                          </th>
                        </tr>

                        <tr>
                          <th className="col-md-1">Rezilta</th>
                          <th className="col-md-6">
                            <div
                              style={{
                                height: 15,
                                width: 150,
                                backgroundColor:
                                  this.algo() === 0
                                    ? "green"
                                    : this.algo() === 1
                                    ? "orange"
                                    : this.algo() === 2
                                    ? "red"
                                    : "grey",
                                color: "#fff",
                                paddingLeft: 10
                              }}>
                              <p>
                                {this.algo() === 0
                                  ? "Ou anfom"
                                  : this.algo() === 1
                                  ? "Atansyon"
                                  : this.algo() === 2
                                  ? "Risk kontaminasyon"
                                  : ""}
                              </p>
                            </div>
                          </th>
                        </tr>
                      </div>
                    </div>
                  ),
                  trigger: "menu"
                },
                {
                  id: "konekte-telrep",
                  user: true,

                  trigger: "question"
                },

                {
                  id: "anrejistre",
                  message: "Kijan ou rele? tape non'w ak siyati'w",
                  trigger: "name"
                },
                {
                  id: "name",
                  user: true,
                  trigger: "3"
                },
                {
                  id: "3",
                  message: "Mèsi {previousValue}! Ki sèks ou?",
                  trigger: "gender"
                },
                {
                  id: "gender",
                  options: [
                    {value: "Gason", label: "Gason", trigger: "5"},
                    {value: "Fanm", label: "Fanm", trigger: "5"}
                  ]
                },
                {
                  id: "5",
                  message: "Ki laj ou?",
                  trigger: "age"
                },

                {
                  id: "age",
                  user: true,
                  trigger: "tel",
                  validator: value => {
                    if (isNaN(value)) {
                      return "Ou ka  antre chif selman";
                    } else if (value < 0) {
                      return "Li pa ka negatif";
                    } else if (value > 120) {
                      return `${value}? Antre yon bon laj!`;
                    }

                    return true;
                  }
                },
                {
                  id: "tel",
                  message: "Nan ki numero telefon pou voye sms pou ou?",
                  trigger: "telrep"
                },
                {
                  id: "telrep",
                  user: true,

                  trigger: "zone"
                },
                {
                  id: "zone",
                  message: "Nan ki zone ou abite?",
                  trigger: "zonerep"
                },
                {
                  id: "zonerep",
                  user: true,
                  trigger: "7"
                },

                {
                  id: "7",
                  message: "Ok! pou rezime",
                  trigger: "review"
                },
                {
                  id: "review",
                  component: <Review />,
                  asMessage: true,
                  trigger: "update"
                },
                {
                  id: "update",
                  message: "Ou vle chanje yon bagay?",
                  trigger: "update-question"
                },
                {
                  id: "update-question",
                  options: [
                    {value: "Wi", label: "Wi", trigger: "update-yes"},
                    {value: "Non", label: "Non", trigger: "end-message"}
                  ]
                },
                {
                  id: "update-yes",
                  message: "Kisa ou vle chanje men'm?",
                  trigger: "update-fields"
                },
                {
                  id: "update-fields",
                  options: [
                    {value: "Non'm", label: "Non'm", trigger: "update-name"},
                    {
                      value: "Sèks mwen",
                      label: "Sèks mwen",
                      trigger: "update-gender"
                    },
                    {
                      value: "Laj mwen",
                      label: "Laj mwen",
                      trigger: "update-age"
                    },
                    {
                      value: "Nimero tel",
                      label: "Nimero tel",
                      trigger: "update-telrep"
                    },
                    {
                      value: "Zon na",
                      label: "Zon na",
                      trigger: "update-zonerep"
                    }
                  ]
                },
                {
                  id: "update-name",
                  update: "name",
                  trigger: "7"
                },
                {
                  id: "update-gender",
                  update: "gender",
                  trigger: "7"
                },
                {
                  id: "update-age",
                  update: "age",
                  trigger: "7"
                },
                {
                  id: "update-telrep",
                  update: "telrep",
                  trigger: "7"
                },
                {
                  id: "update-zonerep",
                  update: "zonerep",
                  trigger: "7"
                },
                {
                  id: "end-message",
                  message: ({previousValue, steps}) => {
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 3000);
                    return "Mèsi!";
                  },
                  trigger: "menu"
                },

                {
                  id: "swivi-senptom",
                  message: ({previousValue, steps}) => {
                    senptom =
                      senptom +
                      (steps.swivisenptom2 && steps.swivisenptom2.message
                        ? steps.swivisenptom2.message + ","
                        : "");
                    console.log(senptom);

                    return "Touche problèm genyen an?";
                  },
                  trigger: "swivisenptom2"
                },
                {
                  id: "swivisenptom2",
                  placeholder: "sddsds",
                  options: [
                    {
                      value: 1,
                      label: "Fyèv",
                      trigger: "swivi-senptom"
                    },
                    // {
                    //   value: 2,
                    //   label: "Grip",
                    //   trigger: "swivi-senptom"
                    // },
                    {
                      value: 3,
                      label: "Respirasyon",
                      trigger: "swivi-senptom"
                    },
                    {
                      value: 4,
                      label: "Tous",
                      trigger: "swivi-senptom"
                    },
                    {
                      value: 5,
                      label: "Etenye",
                      trigger: "swivi-senptom"
                    },
                    {
                      value: 6,
                      label: "Anyen lòt",
                      trigger: "konbyen-jou"
                    }
                  ]
                },
                {
                  id: "konbyen-jou",
                  message: "Sa fe konbyen jou ou santi senptom sa yo?",
                  trigger: "konbyenjourep"
                },
                {
                  id: "konbyenjourep",
                  user: true,
                  trigger: "karanten2",
                  validator: value => {
                    if (isNaN(value)) {
                      return "Ou ka  antre chif selman";
                    } else if (value < 0) {
                      return "Li pa ka negatif";
                    } else if (value > 120) {
                      return `${value}? Antre yon bon laj!`;
                    }

                    return true;
                  }
                },
                {
                  id: "karanten2",
                  message: ({previousValue, steps}) => {
                    nbjour =
                      steps.konbyenjourep && steps.konbyenjourep.value
                        ? steps.konbyenjourep.value
                        : 0;
                    console.log(nbjour);

                    return "Koman ou santi'w?";
                  },

                  trigger: "karanten3"
                },
                {
                  id: "karanten3",
                  options: [
                    {value: 0, label: "Byen", trigger: "karanten4"},
                    {
                      value: 1,
                      label: "Pa tro mal",
                      trigger: "karanten4"
                    },
                    {value: 2, label: "Mal", trigger: "karanten4"},
                    {
                      value: 3,
                      label: "Trè mal",
                      trigger: "karanten4"
                    }
                  ]
                },
                {
                  id: "karanten4",
                  message: ({previousValue, steps}) => {
                    etasante =
                      steps.karanten3 && steps.karanten3.value
                        ? steps.karanten3.value
                        : 0;
                    console.log(etasante);

                    return "Eske ou ka kenbe souf ou e femen bouch ou pandan 10 segond san san ou pa respire?";
                  },

                  trigger: "karanten5"
                },
                {
                  id: "karanten5",
                  options: [
                    {value: "Wi", label: "Wi", trigger: "karanten6"},
                    {value: "Non", label: "Non", trigger: "karanten6"}
                  ]
                },

                {
                  id: "karanten6",
                  message: ({previousValue, steps}) => {
                    testsouf =
                      steps.karanten5 && steps.karanten5.value
                        ? steps.karanten5.value
                        : "";
                    console.log(testsouf);

                    return "Eske ou raple w ke ou te nan zon ki te gen moun ki genyen korona?";
                  },

                  trigger: "karanten7"
                },
                {
                  id: "karanten7",
                  options: [
                    {value: "Wi", label: "Wi", trigger: "karanten"},
                    {value: "Non", label: "Non", trigger: "karanten"}
                  ]
                },

                {
                  id: "karanten",
                  message: ({previousValue, steps}) => {
                    zonexpoz =
                      steps.karanten7 && steps.karanten7.value
                        ? steps.karanten7.value
                        : "";
                    console.log(zonexpoz);

                    console.log({
                      senptom,
                      nbjour,
                      etasante,
                      testsouf,
                      zonexpoz
                    });
                    localStorage.setItem(
                      "analyse",
                      JSON.stringify({
                        senptom,
                        nbjour,
                        etasante,
                        testsouf,
                        zonexpoz
                      })
                    );
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 2000);

                    return "Mesi dèske ou pran tan'w pou w evalye tèt ou.";
                  }
                },

                {
                  id: "nimoro-ijans",
                  message: `Nimero ijans: 
                    4343-3333`,
                  trigger: "menu"
                },
                {
                  id: "tes-korona",
                  message:
                    "Sel MSSPP ki gen otorizasyon pou fè tès Korona viris, Si w bezwen èd rele nan: 4343-3333",
                  trigger: "menu"
                },

                {
                  id: "swivi-senptom-q1",
                  message: "{previousValue}"
                },
                {
                  id: "prevention-korona",
                  component: (
                    <div>
                      <table id="t01" style={{textAlign: "left"}}>
                        <tr>
                          <td>
                            <img
                              src={"/images/lavemen.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={"/images/pamanyenfigi.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={"/images/pabaylanmen.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <img
                              src={"/images/etenyenankoud.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={"/images/retelakay.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={"/images/releijans.png"}
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                                marginTop: 20,
                                marginBottom: 20
                              }}
                            />
                          </td>
                        </tr>
                      </table>
                      ℹ Swiv nouvel otorite yo ap bay sou epidemi an
                    </div>
                  ),
                  trigger: "menu"
                },
                {
                  id: "desinfo-korona",
                  component: (
                    <table id="t01" style={{textAlign: "left"}}>
                      <tr>
                        <td>
                          🔢 Moun ki gen tout laj kapab enfekte avèk kowonaviris
                          la. Moun ki pi gran, ak moun ki gen pre-egziste
                          kondisyon medikal (tankou opresyon, dyabèt, maladi kè)
                          parèt yo dwe pi vilnerab a vin malad grav ak viris la.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          ☀ Kowaviris la ka transmèt nan zòn ki gen klima cho ak
                          imid
                        </td>
                      </tr>
                      <tr>
                        <td>
                          🦟 Koronavirus la PA KAPAB transmèt nan moustik.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          🐶 PA gen okenn prèv ki montre bèt konpayon / bèt kay
                          tankou chen oswa chat ka transmèt kowonaviris la.
                        </td>
                      </tr>
                      <tr>
                        <td>🛀 Pran yon beny cho PA anpeche kowonaviris la</td>
                      </tr>

                      <tr>
                        <td>
                          🟣 Limyè iltravyolèt PA ta dwe itilize pou
                          esterilizasyon epi li ka lakòz iritasyon po
                        </td>
                      </tr>
                      <tr>
                        <td>
                          🌡 Eskanè tèmik yo ka detekte si moun yo gen yon lafyèv
                          men yo pa ka detekte si wi ou non yon moun gen
                          kowonaviris la
                        </td>
                      </tr>
                      <tr>
                        <td>
                          💦 Flite alkòl oswa klò nan tout kò ou PAP touye viris
                          ki te deja antre nan kò ou
                        </td>
                      </tr>
                      <tr>
                        <td>
                          💉 Vaksen kont nemoni, tankou vaksen nemokosik ak
                          vaksen Haibophilus influenzae type b (Hib), PA bay
                          pwoteksyon kont kowonaviris la.
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          PA gen okenn prèv ke regilyèman rense nen an ak saline
                          pwoteje moun kont enfeksyon ak kowonaviris la.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          pa gen okenn prèv ki soti nan epidemi aktyèl la ke
                          manje lay pwoteje moun ki sòti nan coronavirus la.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          💊 Antibyotik PA travay kont viris, antibyotik travay
                          sèlman kont bakteri.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          🧪 Jiska dat, pa gen medikaman espesifik ki rekòmande
                          pou anpeche oswa trete kowonaviris la.
                        </td>
                      </tr>
                    </table>
                  ),
                  trigger: "menu"
                },

                {
                  id: "nb-korona",
                  component: (
                    <div
                      style={{
                        fontSize: 10,
                        width: 300,
                        fontSize: 25,
                        color: "#000",
                        border: "#fff solid 2px"
                      }}>
                      <tr>
                        <th style={{width: 100}}>Enfekte</th>
                        <th style={{width: 100}}>Geri</th>
                        <th style={{width: 100}}>Mouri</th>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: 100,
                            color: "orange",
                            fontWeight: "bold"
                          }}>
                          {localStorage.getItem("nb")
                            ? JSON.parse(localStorage.getItem("nb")).cases
                            : ""}
                        </td>
                        <td
                          style={{
                            width: 100,
                            color: "green",
                            fontWeight: "bold"
                          }}>
                          {localStorage.getItem("nb")
                            ? JSON.parse(localStorage.getItem("nb")).recovered
                            : ""}
                        </td>
                        <td
                          style={{
                            width: 100,
                            color: "red",
                            fontWeight: "bold"
                          }}>
                          {localStorage.getItem("nb")
                            ? JSON.parse(localStorage.getItem("nb")).deaths
                            : ""}
                        </td>
                      </tr>
                    </div>
                  ),

                  trigger: "menu"
                },

                {
                  id: "menu",
                  options: [
                    {
                      value: "Tounen nan meni an",
                      label: "Tounen nan meni an",
                      trigger: "question"
                    },
                    {
                      value: "Ok, mesi m fini",
                      label: "Ok, mesi m fini",
                      trigger: "mesi"
                    }
                  ]
                },
                {
                  id: "mesi",
                  message:
                    "Mesi desjke ou te fe nou konfians pa ezite retounen.",
                  trigger: "menu"
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleForm;
